import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { effectHooks } from '../../helpers';
import { PrivacyModuleService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class PrivacyPolicyStoreEffects {
  constructor(
    private dataService: PrivacyModuleService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ onFailure, onSuccess }) =>
        this.dataService.loadPrivacyPolicy().pipe(
          effectHooks({ onFailure, onSuccess }),
          map((response: string) => {
            return fromActions.loadSuccess({ data: response });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}

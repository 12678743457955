import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { effectHooks } from '../../helpers';
import { MaintenanceReport } from '../../models';
import { MaintenanceReportsService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class MaintenanceReportsStoreEffects {
  constructor(
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private dataService: MaintenanceReportsService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ request }) =>
        this.dataService.load(request).pipe(
          map(({ data: reports }: IResponseSuccess<MaintenanceReport[]>) => {
            return fromActions.loadSuccess({ reports });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure({ error }));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ request, onSuccess }) =>
        this.dataService.create(request).pipe(
          effectHooks({ onSuccess }),
          map(({ data: [report] }: IResponseSuccess<MaintenanceReport[]>) => {
            this.notifications.success('notifications.generic_success');
            return fromActions.createSuccess({ report });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure({ error }));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ request, onSuccess }) =>
        this.dataService.update(request).pipe(
          effectHooks({ onSuccess }),
          map(({ data: [report] }: IResponseSuccess<MaintenanceReport[]>) => {
            this.notifications.success('notifications.generic_success');
            return fromActions.updateSuccess({ report });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure({ error }));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ request }) =>
        this.dataService.delete(request).pipe(
          map(() => {
            this.notifications.success('notifications.generic_success');
            return fromActions.deleteSuccess({ report_id: request.report_id });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure({ error }));
          }),
        ),
      ),
    ),
  );
}

import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { HelpStoreActions } from './root-store/help-store';
import { RootState } from './root-store/root-state';
import { UserMeStoreActions } from './root-store/user-me-store';
import { emitMobileAppEvent } from './helpers';

@Component({
  selector: 'by-root',
  templateUrl: './app.component.html',
  styles: [
    `
      router-outlet + .loader {
        opacity: 1;
      }

      .loader {
        opacity: 0;
        position: fixed;
        height: 100%;
        width: 100%;
        text-align: center;
        z-index: -1;
        transition: opacity 0.8s ease-out;
      }
    `,
  ],
})
export class AppComponent implements OnDestroy, AfterViewInit {
  private subs = new SubSink();

  constructor(
    private router: Router,
    private store: Store<RootState>,
    private translate: TranslateService,
  ) {
    const lang = (
      window.navigator['userLanguage'] || window.navigator.language
    ).slice(0, 2);
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.store.dispatch(UserMeStoreActions.loadRequest({ redirect: null }));

    this.subs.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe(() => {
          this.store.dispatch(HelpStoreActions.resetGuideSectionsToView());
        }),
    );
  }

  ngAfterViewInit(): void {
    emitMobileAppEvent(
      'logErrors',
      JSON.stringify({
        error: JSON.stringify({ message: 'AppComponent loaded' }),
      }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}

export interface State {
  privacyPolicy: string;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  privacyPolicy: null,
  loading: false,
  error: null,
};

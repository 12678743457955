import { IBookingEngineFirstStep } from '@app/models';
import { createAction, props } from '@ngrx/store';

import {
  IBookingEngine,
  IBookingEngineSecondStep,
  IBookingEngineSecondStepSelected,
} from './../../models/objects/booking-engine';

export const loadBookingEngineRequest = createAction(
  '[Booking Engine] Load Request',
  props<{
    widgetCode: string;
  }>(),
);

export const loadBookingEngineSuccess = createAction(
  '[Booking Engine] Load Success',
  props<{ data: IBookingEngine }>(),
);

export const loadBookingEngineFailure = createAction(
  '[Booking Engine] Load Failure',
  props<{ error: any }>(),
);
export const loadBookingEngineFirstStepRequest = createAction(
  '[Booking Engine] Load First Step Request',
  props<{
    objects: string;
    lang: string;
    widgetCode: string;
    propertyId?: number;
  }>(),
);

export const loadBookingEngineFirstStepSuccess = createAction(
  '[Booking Engine] Load First Step Success',
  props<{ data: IBookingEngineFirstStep }>(),
);

export const loadBookingEngineFirstStepFailure = createAction(
  '[Booking Engine] Load First Step Failure',
  props<{ error: any }>(),
);

export const loadBookingEngineSecondStepRequest = createAction(
  '[Booking Engine] Load Second Step Request',
  props<{ dataSecondStep: string; widget_code: string }>(),
);

export const loadBookingEngineSecondStepSuccess = createAction(
  '[Booking Engine] Load Second Step Success',
  props<{ data: IBookingEngineSecondStepSelected }>(),
);

export const loadBookingEngineSecondStepFailure = createAction(
  '[Booking Engine] Load Second Step Failure',
  props<{ error: any }>(),
);

export const resetBookingEngineState = createAction(
  '[Booking Engine] Reset State',
);

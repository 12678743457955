import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CheckReservationChannel } from '../models';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { ChannelAccommodationDownloadSummary } from '../core/models/api/generics/channel-tools-response/channel-tools-response.interface';

@Injectable({
  providedIn: 'root',
})
export class CheckReservationChannelService {
  constructor(private http: HttpClient) {}

  checkReservation(data: CheckReservationChannel) {
    return this.http.get<IResponseSuccess<ChannelAccommodationDownloadSummary>>(
      `channelmanager/xml_resend/${data.channel_id}/${data.xml_reservation_id}/${data.xml_property_id}?with_channel_response=1&with_channel_request=1`,
    );
  }
}

import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { IAddonRoot } from './../../models/objects/addons';
import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;
export const getData = (state: State) => state.data;

export const selectAddonsInvoiceState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('addonsInvoice');

export const selectAddonsInvoiceError: MemoizedSelector<
  object,
  any
> = createSelector(
  selectAddonsInvoiceState,
  getError,
);

export const selectAddonsInvoiceIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  selectAddonsInvoiceState,
  getIsLoading,
);
export const selectAllAddonsInvoiceItems: MemoizedSelector<
  object,
  { [invoiceId: number]: IAddonRoot[] }
> = createSelector(
  selectAddonsInvoiceState,
  getData,
);

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { CheckReservationChannelService } from '../../services/check-reservation-channel.service';

import * as fromActions from './actions';
import { effectHooks } from '../../helpers';

@Injectable()
export class CheckReservationChannelStoreEffects {
  constructor(
    private dataService: CheckReservationChannelService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  download$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.checkReservarionChannelRequest),
      switchMap(({ data, hooks }) =>
        this.dataService.checkReservation(data).pipe(
          effectHooks(hooks),
          map(() => {
            return fromActions.checkReservarionChannelSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.checkReservarionChannelFailure(error));
          }),
        ),
      ),
    ),
  );
}

import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectScaState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('sca');

export const selectScaError: MemoizedSelector<object, any> = createSelector(
  selectScaState,
  getError,
);

export const selectScaIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  selectScaState,
  getIsLoading,
);

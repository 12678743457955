import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { PolicyType } from '../../models';

export const featureAdapter: EntityAdapter<PolicyType> = createEntityAdapter<
  PolicyType
>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<PolicyType> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});

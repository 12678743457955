import { IAddonRoot } from '@app/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const featureAdapter: EntityAdapter<IAddonRoot> = createEntityAdapter<
  IAddonRoot
>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<IAddonRoot> {
  data: { [invoiceId: number]: IAddonRoot[] };
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  data: null,
  isLoading: false,
  error: null,
});

import { Directive } from '@angular/core';

@Directive({
  selector: '[byBeddy]',
})
export class BeddyDirective {
  constructor() {
    document.addEventListener('keyup', (e) => {
      if (e.ctrlKey && e.which === 76) {
        const dimension = 294;
        const posx = (
          Math.random() *
          (window.innerWidth - dimension)
        ).toFixed();
        const posy = (
          Math.random() *
          (window.innerHeight - dimension)
        ).toFixed();

        const image = document.createElement('img');
        image.src = 'assets/images/general/logo/logo-beddy-1.png';
        image.style.position = 'absolute';
        image.style.left = posx + 'px';
        image.style.top = posy + 'px';

        image.width = dimension;
        image.height = dimension;

        document.querySelector('by-root').appendChild(image);
      }

      if (e.ctrlKey && e.which === 73) {
        const dimensionW = 300;
        const dimensionH = 75;
        const posx = (window.innerWidth / 2 - dimensionW / 2).toFixed();
        const posy = (window.innerHeight / 2 - dimensionH / 2).toFixed();

        const image = document.createElement('img');
        image.src = 'assets/images/general/logo/logo-beddy-2.png';
        image.style.position = 'absolute';
        image.style.left = posx + 'px';
        image.style.top = posy + 'px';

        image.width = dimensionW;
        image.height = dimensionH;

        document.querySelector('by-root').appendChild(image);
      }
    });
  }
}

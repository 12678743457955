import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { reports }) =>
    fromState.featureAdapter.setAll(reports, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.createSuccess, (state, { report }) =>
    fromState.featureAdapter.addOne(report, state),
  ),

  on(fromActions.updateSuccess, (state, { report }) =>
    fromState.featureAdapter.updateOne(
      { id: report.id, changes: report },
      state,
    ),
  ),

  on(fromActions.deleteSuccess, (state, { report_id }) =>
    fromState.featureAdapter.removeOne(report_id, state),
  ),

  on(fromActions.resetState, () => fromState.initialState),
);

export function maintenanceReportsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}

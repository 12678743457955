import { IExtraBedRootStore } from '@app/models';

export interface State {
  isLoading?: boolean;
  error?: any;
  extraBedsProperties: IExtraBedRootStore;
}

export const initialState: State = {
  isLoading: false,
  error: null,
  extraBedsProperties: null,
};

import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserPreferencesStoreSelectors } from '@app/root-store/user-preferences-store';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/internal/Subscription';

import { ICoreState } from '../+state/core.reducer';

@Directive({
  selector: '[byLinkCustom]',
})
export class LinkCustomDirective implements OnInit, OnDestroy {
  preferenceSubscriptions: Subscription;

  preferences: any;
  keyPress: any = [];

  startTime;
  endTime;

  constructor(private _store: Store<ICoreState>, private router: Router) {}

  ngOnInit() {
    this.preferenceSubscriptions = this._store
      .select(UserPreferencesStoreSelectors.selectUserPreferencesData)
      .subscribe((preference) => {
        if (!Object.keys(preference || {}).length) {
          return;
        }
        this.preferences = preference['customLinkMenu'] || {};
      });

    document.addEventListener('keyup', (e) => {
      if (!Object.keys(this.preferences || {}).length) {
        return;
      }
      if (this.keyPress.length === 2) {
        const findPreference = this.preferences[this.keyPress[1].toLowerCase()];
        if (findPreference) {
          this.keyPress = [];
          const diffTime = this.endTime - this.startTime;
          if (diffTime > 200) {
            this.router.navigate([findPreference.link]);
          }
        }
      }
      this.keyPress = [];
      this.startTime = null;
      this.endTime = null;
    });

    document.addEventListener('keydown', (e: any) => {
      if (!Object.keys(this.preferences || {}).length) {
        return;
      }
      if (this.keyPress.length) {
        this.endTime = new Date().getTime();
        this.keyPress.push(e.key);
      } else if (e.key === 'b' && !this.keyPress.length) {
        this.startTime = new Date().getTime();
        this.keyPress.push(e.key);
      }
    });
  }
  ngOnDestroy() {
    try {
      this.preferenceSubscriptions.unsubscribe();
    } catch (error) {}
  }
}

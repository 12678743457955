import { createAction, props } from '@ngrx/store';

import { PolicyType } from '../../models';

export const loadRequest = createAction('[Policy Types] Load Request');

export const loadSuccess = createAction(
  '[Policy Types] Load Success',
  props<{ items: PolicyType[] }>(),
);

export const loadFailure = createAction(
  '[Policy Types] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Policy Types] Reset State');

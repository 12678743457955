import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PrivacyPolicyStoreEffects } from './effects';
import { privacyPolicyReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('privacyPolicy', privacyPolicyReducer),
    EffectsModule.forFeature([PrivacyPolicyStoreEffects]),
  ],
})
export class PrivacyPolicyStoreModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BookingEngineService {
  constructor(private http: HttpClient) {}

  load(widgetCode: string) {
    return this.http.get(`BOL/widget/${widgetCode}`);
  }
  loadFirstStep(
    objects: string,
    lang: string,
    widgetCode: string,
    propertyId?: number,
  ) {
    if (propertyId) {
      return this.http.get(
        `BOL/widget/${widgetCode}/general_details/property/${propertyId}?objects=${objects}&lang=${lang}`,
      );
    }
    return this.http.get(
      `BOL/widget/${widgetCode}/general_details/properties?objects=${objects}&lang=${lang}`,
    );
  }

  loadSecondStep(dataSecondStep: string) {
    return this.http.post(`BOL/search?${dataSecondStep}`, dataSecondStep);
  }
}

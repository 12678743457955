import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MaintenanceReportsStoreEffects } from './effects';
import { maintenanceReportsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('maintenanceReports', maintenanceReportsReducer),
    EffectsModule.forFeature([MaintenanceReportsStoreEffects]),
  ],
})
export class MaintenanceReportsStoreModule {}

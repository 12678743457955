import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../../environments/environment';

import { AccommodationContentApiStoreModule } from './accommodation-content-api-store';
import { AccommodationTypesStoreModule } from './accommodation-types-store';
import { AccommodationsStoreModule } from './accommodations-store';
import { ActiveChannelsStoreModule } from './active-channels-store';
import { ActiveChargesStoreModule } from './active-charges-store';
import { ActiveModulesStoreModule } from './active-modules-store';
import { AddBillModalStoreModule } from './add-bill-modal-store';
import { AddonCategoriesStoreModule } from './addon-categories-store';
import { AddonsInvoiceStoreModule } from './addons-invoice-store';
import { AddonsStoreModule } from './addons-store';
import { DeleteCustomersFromEmailStoreModule } from './admin-delete-customers-from-email-store';
import { AdminStatsStoreModule } from './admin-stats-store';
import { AdrRevparChartWidgetStoreModule } from './adr-revpar-chart-widget-store';
import { ApiPartnerManagerStoreModule } from './api-partner-manager-store';
import { ApiPartnerUsersStoreModule } from './api-partner-users-store';
import { ApiPartnersLookupStoreModule } from './api-partners-lookup-store';
import { ApiPartnersStoreModule } from './api-partners-store';
import { AuthStoreModule } from './auth-store';
import { AuthVerificationMethodsStoreModule } from './auth-verification-methods-store';
import { AvailableRoomsStoreModule } from './available-rooms-store';
import { BedTypesStoreModule } from './bed-types-store';
import { BillsDepartmentsStoreModule } from './bills-departments-store';
import { BillsOrdinationStoreModule } from './bills-ordination';
import { BookingEngineStoreModule } from './booking-engine-store/booking-engine-store.module';
import { BookingPromotionsStoreModule } from './booking-promotions-store';
import { BookingWidgetsBannersStoreModule } from './booking-widgets-banners-store';
import { BookingWidgetsLinksStoreModule } from './booking-widgets-links-store';
import { BookingWidgetsStoreModule } from './booking-widgets-store';
import { CartTypesStoreModule } from './cart-types-store';
import { CashClosingStoreModule } from './cash-closing-store';
import { CashDeskStoreModule } from './cash-desk-store';
import { ChannelContentAPIStoreModule } from './channel-content-api-store';
import { ChannelGuestReviewsStoreModule } from './channel-guest-reviews-store/channel-guest-reviews-store.module';
import { ChannelOptionsStoreModule } from './channel-options-store';
import { ChannelScoreStoreModule } from './channel-score-store/channel-score-store.module';
import { ChannelsCheckInStoreModule } from './channels-check-in-store';
import { ChannelsStoreModule } from './channels-store';
import { CheckObStoreModule } from './check-ob-store';
import { CheckReservationChannelStoreModule } from './check-reservation-channel-store/check-reservation-channel-store.module';
import { ChildrenRangeStoreModule } from './children-range-store';
import { CityDestinationsStoreModule } from './city-destinations-store';
import { CityTaxStoreModule } from './city-tax-store';
import { CityTaxSummaryStatisticsStoreModule } from './city-tax-summary-statistics';
import { CityTaxSummaryStoreModule } from './city-tax-summary-store';
import { CleanablesStoreModule } from './cleanables-store';
import { CloneableOptionsStoreModule } from './cloneable-options-store';
import { CommissionsStoreModule } from './commissions-store';
import { CompaniesStoreModule } from './companies-store';
import { CompanyPaymentsStoreModule } from './company-payments-store';
import { CompletedAccommodationsStoreModule } from './completed-accommodations-store';
import { ContentAPICreditCardsStoreModule } from './content-api-credit-cards-store';
import { ContentAPILeadTimeHoursStoreModule } from './content-api-lead-time-hours-store';
import { ContentAPILeadTimeStoreModule } from './content-api-lead-time-store';
import { ContentAPIPoliciesStoreModule } from './content-api-policies-store';
import { CountriesStoreModule } from './countries-store';
import { CouponsStoreModule } from './coupons-store';
import { CredentialsCheckStoreModule } from './credential-check-store';
import { CustomerPaymentsStoreModule } from './customer-payments-store';
import { CustomersStatsWidgetsStoreModule } from './customers-stats-widgets-store';
import { CustomersStoreModule } from './customers-store';
import { CutoffStoreModule } from './cutoff-store';
import { DailyClosureWidgetsStoreModule } from './daily-closure-widgets-store';
import { DailyMealsStoreModule } from './daily-meals-store';
import { DailyOccupancyChartWidgetStoreModule } from './daily-occupancy-chart-widget-store';
import { DailyOverviewTreatmentsStoreModule } from './daily-overview-treatments-store';
import { DailySummaryStoreModule } from './daily-summary-store';
import { DateOfCheckinStoreModule } from './date-of-checkin-store';
import { DateOfStayStoreModule } from './date-of-stay-store';
import { DealersStoreModule } from './dealers-store';
import { DepositStoreModule } from './deposit-store';
import { DepositsStatsStoreModule } from './deposits-stats-store';
import { DocumentScanStoreModule } from './document-scan-store';
import { DownloadMappingChannelStoreModule } from './download-mapping-channel-store/download-mapping-channel-store.module';
import { DownloadRatesStoreModule } from './download-rates-store';
import { EmailTemplatePreviewStoreModule } from './email-template-preview-store';
import { EmailTemplatesCustomStoreModule } from './email-templates-custom-store';
import { EmailTemplatesSystemStoreModule } from './email-templates-system-store';
import { EstimateAddonPriceStoreModule } from './estimate-addon-price-store';
import { EstimateStoreModule } from './estimate-store';
import { EventsModalAttacherStoreModule } from './events-modal-attacher-store';
import { EventsStoreModule } from './events-store';
import { ExpensesCategoriesStoreModule } from './expenses-categories-store';
import { ExpensesStoreModule } from './expenses-store';
import { ExpiringPriceQuotationOptionsStoreModule } from './expiring-price-quotation-options-store';
import { ExpiringReservationsOptionsStoreModule } from './expiring-reservation-options-store';
import { ExternalServicesMappingStoreModule } from './external-services-mapping-store';
import { ExternalServicesStoreModule } from './external-services-store';
import { ExtraBedsStoreModule } from './extra-beds-store';
import { FacilitiesStoreModule } from './facilities-store';
import { FeesStoreModule } from './fees-store';
import { FiscalPrinterModelsStoreModule } from './fiscal-printer-models-store';
import { FiscalPrintersStoreModule } from './fiscal-printers-store';
import { FloorsStoreModule } from './floors-store';
import { FundMovementsStoreModule } from './fund-movements-store';
import { GdprPrivacyStoreModule } from './gdpr-privacy-store';
import { GlobalSearchStoreModule } from './global-search-store';
import { GuestCardTypesStoreModule } from './guest-card-types-store';
import { GuestCardsStoreModule } from './guest-cards-store';
import { HelpStoreModule } from './help-store';
import { HousedWebReceiptsStoreModule } from './housed-web-receipts-store';
import { HousedWebStoreModule } from './housed-web-store';
import { HousekeeperDashboardStoreModule } from './housekeeper-dashboard-store';
import { HousekeeperRecordTasksStoreModule } from './housekeeper-record-tasks-store';
import { HousekeeperScenariosStoreModule } from './housekeeper-scenarios-store';
import { HousekeeperSettingsStoreModule } from './housekeeper-settings-store';
import { HousekeeperSortablesStoreModule } from './housekeeper-sortables-store';
import { HousekeeperStatisticsStoreModule } from './housekeeper-statistics-store';
import { HousekeeperTasksStoreModule } from './housekeeper-tasks-store';
import { HousekeeperUsersStoreModule } from './housekeeper-users-store';
import { IcsCalendarsStoreModule } from './ics-calendars-store';
import { InvoiceChannelsStoreModule } from './invoice-channels-store/invoice-channels-store.module';
import { InvoiceNumberStoreModule } from './invoice-number-store';
import { InvoicePrintSettingsStoreModule } from './invoice-print-settings-store';
import { InvoiceTypeStoreModule } from './invoice-type-store';
import { InvoiceArchivesStoreModule } from './invoices-archives-store';
import { InvoicesLayoutsStoreModule } from './invoices-layouts-store';
import { InvoicesModuleStoreModule } from './invoices-module-store';
import { InvoicesReceiptsStoreModule } from './invoices-receipts-store';
import { InvoicesStatsStoreModule } from './invoices-stats-store';
import { InvoicesStoreModule } from './invoices-store';
import { IstatGroupsStoreModule } from './istat-groups-store';
import { IstatGuestsCardStoreModule } from './istat-guests-card-store';
import { IstatGuestsReportStoreModule } from './istat-guests-report-store';
import { IstatSettingsStoreModule } from './istat-settings-store';
import { LengthOfStayStoreModule } from './length-of-stay-store';
import { LogDetailsStoreModule } from './log-details-store';
import { LogsStoreModule } from './logs-store';
import { MaintenanceReportsStoreModule } from './maintenance-reports-store/maintenance-reports-store.module';
import { MappingsStoreModule } from './mapping-store';
import { MasterUserPropertiesStoreModule } from './master-user-properties';
import { MediasStoreModule } from './medias-store';
import { MessageFormatStoreModule } from './message-format-store';
import { MetasearchStoreModule } from './metasearch';
import { ModulesStoreModule } from './modules-store';
import { MovedBillInformationsStoreModule } from './moved-bill-informations-store';
import { NotificationsSettingsStoreModule } from './notifications-settings-store';
import { OccupancyChartWidgetStoreModule } from './occupancy-chart-widget-store';
import { OpenedInvoicesStatsStoreModule } from './opened-invoices-stats-store';
import { PasswordResetStoreModule } from './password-reset-store';
import { PaymentMethodsStoreModule } from './payment-methods-store';
import { PaymentOrdersStoreModule } from './payment-orders-store';
import { PaymentTermsStoreModule } from './payment-terms-store';
import { PaymentsStoreModule } from './payments-store';
import { PermissionsStoreModule } from './permissions-store';
import { PlaceVatQuoteStoreModule } from './place-vat-quotes-store';
import { PoliciesStoreModule } from './policies-store';
import { PolicyTypesStoreModule } from './policy-types-store';
import { PosStoreModule } from './pos-store';
import { PreviousReservationsTaskRecordsStoreModule } from './previous-reservations-task-records-store';
import { PreviousReservationsTasksStoreModule } from './previous-reservations-tasks-store';
import { PriceListsStoreModule } from './price-lists-store';
import { PriceQuotationStoreModule } from './price-quotation-details-store';
import { PriceQuotationsStatsStoreModule } from './price-quotations-stats-store';
import { PriceQuotationsStoreModule } from './price-quotations-store';
import { PrivacyPolicyStoreModule } from './privacy-policy-store';
import { ProductionAccommodationsStoreModule } from './production-accommodations-store';
import { ProductionDepartmentsStoreModule } from './production-departments-store';
import { ProductionOccupancyStoreModule } from './production-occupancy-store';
import { ProductionReservationsStoreModule } from './production-reservations-store';
import { ProductionTreatmentsStoreModule } from './production-treatments-store';
import { PropertiesAttributesStoreModule } from './properties-attributes-store';
import { PropertiesDocumentsStoreModule } from './properties-documents-store';
import { PropertiesExpireDateStoreModule } from './properties-expire-date-store';
import { PropertiesPaymentsStoreModule } from './properties-payments-store';
import { PropertiesStoreModule } from './properties-store';
import { PropertiesWarningsStoreModule } from './properties-warnings-store';
import { PropertyApiPartnerUsersStoreModule } from './property-api-partner-users-store';
import { PropertyExternalServicesStoreModule } from './property-external-services-store';
import { PropertyGroupUsersStoreModule } from './property-group-users-store';
import { PropertyTypesStoreModule } from './property-types-store';
import { PropertyUsersStoreModule } from './property-users-store';
import { ProportionalDayPriceStoreModule } from './proportional-day-price-store';
import { PushNotificationsStoreModule } from './push-notifications-store';
import { QuestionsDatesOfSearchStoreModule } from './questions-dates-of-search-store';
import { QuestionsDatesOfStayStoreModule } from './questions-dates-of-stay-store';
import { QuoteRequestWidgetsStoreModule } from './quote-request-widgets-store';
import { QuoteRequestsStatsStoreModule } from './quote-requests-stats-store';
import { QuoteRequestsStoreModule } from './quote-requests-store';
import { RateplansStoreModule } from './rateplans-store';
import { RejectedInvoicesWidgetStoreModule } from './rejected-invoices-widget-store';
import { RelatedUsersStoreModule } from './related-users-store';
import { RemoteCheckAccommodationStatusStoreModule } from './remote-check-accommodation-status-store';
import { ResellersStoreModule } from './resellers-store';
import { ResendAllRatesChannelsStoreModule } from './resend-all-rates-channels-store';
import { ReservationDetailsBillsStoreModule } from './reservation-details-bills-store';
import { ReservationDetailsCancellationPolicyStoreModule } from './reservation-details-cancellation-policy-store';
import { ReservationDetailsInvoicesStoreModule } from './reservation-details-invoices-store';
import { ReservationDetailsMessagesStoreModule } from './reservation-details-messages-store';
import { ReservationDetailsPaymentsStoreModule } from './reservation-details-payments-store';
import { ReservationDetailsCustomStoreModule } from './reservation-details-custom-store';
import { ReservationFromsStoreModule } from './reservation-froms-store';
import { ReservationReasonsStoreModule } from './reservation-reasons-store';
import { ReservationTagStoreModule } from './reservation-tag-store';
import { ReservationsStatsStoreModule } from './reservations-stats-store';
import { ReservationsStoreModule } from './reservations-store';
import { ReservationsActiveModelsStoreModule } from './reservatios-active-store';
import { RevenueChartWidgetStoreModule } from './revenue-chart-widget-store';
import { RoleDetailsStoreModule } from './role-details-store';
import { RolePresetsStoreModule } from './role-presets-store';
import { RoleUserDetailsStoreModule } from './role-user-details';
import { RoleStoreModule } from './roles-store';
import { ScaConfirmationModuleConfigurationStoreModule } from './sca-confirmation-module-configuration-store/sca-confirmation-module-configuration-store.module';
import { ScaStoreModule } from './sca-store';
import { SendinblueStoreModule } from './sendinblue-store';
import { SnippetsStoreModule } from './snippets-store';
import { SortPropertiesStoreModule } from './sort-properties-store';
import { SourceCountriesChartStoreModule } from './source-countries-chart-store';
import { SourceCountriesStoreModule } from './source-countries-store';
import { StatisticsStoreModule } from './statistics-store';
import { StatsProductionStoreModule } from './stats-production-store';
import { StatsStoreModule } from './stats-store';
import { StripeCustomerStoreModule } from './stripe-customer-store';
import { SubdivisionAccountsStoreModule } from './subdivision-accounts';
import { SuspendedInvoicesWidgetStoreModule } from './suspended-invoices-widget-store';
import { TableauNumbersStoreModule } from './tableau-numbers-store';
import { TableauReservationDetailsStoreModule } from './tableau-reservation-details-store';
import { TemporaryInvoiceStoreModule } from './temporary-invoice-store';
import { TreatmentTypesStoreModule } from './treatment-types-store';
import { TreatmentsStoreModule } from './treatments-store';
import { TypeOfGuestsStoreModule } from './type-of-guests-store';
import { UserMePermissionsStoreModule } from './user-me-permissions-store';
import { UserMeStoreModule } from './user-me-store';
import { UserNotesStoreModule } from './user-notes-store';
import { UserPreferencesStoreModule } from './user-preferences-store';
import { UserPropertiesAttachedStoreModule } from './user-properties-attached-store';
import { UserSessionsStoreModule } from './user-sessions-store';
import { UserTimezonesStoreModule } from './user-timezones-store';
import { UsersStoreModule } from './users-store';
import { VatQuotesAdminSettingsStoreModule } from './vat-quotes-admin-settings-store';
import { VatQuoteStoreModule } from './vat-quotes-store';
import { ViewSdiFormatStoreModule } from './view-sdi-format-store/view-sdi-format-store.module';
import { VirtualChannelStoreModule } from './virtual-channel-store';
import { WarningsStoreModule } from './warnings-store';
import { WelcomeAutomaticEmailsStoreModule } from './welcome-automatic-emails-store';
import { WelcomeAutomaticEmailsTemplateStoreModule } from './welcome-automatic-emails-template-store';
import { ZdlakeStoreModule } from './zdlake-store';
import { ZipCodeDestinationsStoreModule } from './zip-code-destinations-store';
import { CityTaxPresetsStoreModule } from './city-tax-presets-store';
import { ChannelIdentifyPropertyStoreModule } from './channel-identify-property';
import { SystemInformationsStoreModule } from './system-informations';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AddonCategoriesStoreModule,
    PosStoreModule,
    VatQuoteStoreModule,
    InvoicesLayoutsStoreModule,
    AddonsStoreModule,
    AddonsInvoiceStoreModule,
    InvoicesStoreModule,
    CityTaxStoreModule,
    BedTypesStoreModule,
    ReservationTagStoreModule,
    VatQuoteStoreModule,
    PoliciesStoreModule,
    DepositStoreModule,
    PaymentsStoreModule,
    EmailTemplatesCustomStoreModule,
    ExtraBedsStoreModule,
    AccommodationsStoreModule,
    PlaceVatQuoteStoreModule,
    RateplansStoreModule,
    PriceListsStoreModule,
    TreatmentsStoreModule,
    TableauNumbersStoreModule,
    EventsStoreModule,
    NotificationsSettingsStoreModule,
    CustomersStoreModule,
    CompaniesStoreModule,
    ReservationReasonsStoreModule,
    ReservationFromsStoreModule,
    DealersStoreModule,
    PaymentMethodsStoreModule,
    CartTypesStoreModule,
    FacilitiesStoreModule,
    CouponsStoreModule,
    UsersStoreModule,
    ChildrenRangeStoreModule,
    CredentialsCheckStoreModule,
    PriceQuotationsStoreModule,
    UserPreferencesStoreModule,
    PriceQuotationStoreModule,
    PermissionsStoreModule,
    RoleStoreModule,
    RoleDetailsStoreModule,
    RolePresetsStoreModule,
    RoleUserDetailsStoreModule,
    MasterUserPropertiesStoreModule,
    UserPropertiesAttachedStoreModule,
    CityTaxSummaryStoreModule,
    HousedWebStoreModule,
    HousedWebReceiptsStoreModule,
    MediasStoreModule,
    GlobalSearchStoreModule,
    ReservationsStoreModule,
    ResellersStoreModule,
    IstatGuestsCardStoreModule,
    CashDeskStoreModule,
    CashClosingStoreModule,
    FeesStoreModule,
    BookingEngineStoreModule,
    InvoicesReceiptsStoreModule,
    UserMePermissionsStoreModule,
    ActiveModulesStoreModule,
    ActiveChargesStoreModule,
    DocumentScanStoreModule,
    PriceQuotationsStatsStoreModule,
    StatisticsStoreModule,
    StatsProductionStoreModule,
    TreatmentTypesStoreModule,
    SubdivisionAccountsStoreModule,
    FiscalPrintersStoreModule,
    FiscalPrinterModelsStoreModule,
    UserTimezonesStoreModule,
    UserMeStoreModule,
    AccommodationTypesStoreModule,
    PropertyTypesStoreModule,
    ChannelsStoreModule,
    ActiveChannelsStoreModule,
    PolicyTypesStoreModule,
    WarningsStoreModule,
    PropertiesWarningsStoreModule,
    PropertiesStoreModule,
    ReservationsActiveModelsStoreModule,
    ScaStoreModule,
    PaymentTermsStoreModule,
    MappingsStoreModule,
    EmailTemplatePreviewStoreModule,
    CompletedAccommodationsStoreModule,
    ExpiringReservationsOptionsStoreModule,
    ExpiringPriceQuotationOptionsStoreModule,
    EstimateAddonPriceStoreModule,
    BookingWidgetsStoreModule,
    BookingWidgetsLinksStoreModule,
    FundMovementsStoreModule,
    InvoicePrintSettingsStoreModule,
    DownloadRatesStoreModule,
    DownloadMappingChannelStoreModule,
    IstatGuestsReportStoreModule,
    VatQuotesAdminSettingsStoreModule,
    IstatSettingsStoreModule,
    ChannelContentAPIStoreModule,
    SortPropertiesStoreModule,
    ReservationDetailsCustomStoreModule,
    ReservationDetailsBillsStoreModule,
    ReservationDetailsInvoicesStoreModule,
    VirtualChannelStoreModule,
    AccommodationContentApiStoreModule,
    ExpensesStoreModule,
    ExpensesCategoriesStoreModule,
    ContentAPIPoliciesStoreModule,
    ContentAPICreditCardsStoreModule,
    BookingWidgetsBannersStoreModule,
    ExternalServicesStoreModule,
    PropertyExternalServicesStoreModule,
    ExternalServicesMappingStoreModule,
    CountriesStoreModule,
    ApiPartnersStoreModule,
    ApiPartnerUsersStoreModule,
    PropertyUsersStoreModule,
    PropertyApiPartnerUsersStoreModule,
    ApiPartnerManagerStoreModule,
    IcsCalendarsStoreModule,
    CheckReservationChannelStoreModule,
    ChannelIdentifyPropertyStoreModule,
    ResendAllRatesChannelsStoreModule,
    DailyMealsStoreModule,
    LogsStoreModule,
    LogDetailsStoreModule,
    StatsStoreModule,
    AddBillModalStoreModule,
    PropertiesAttributesStoreModule,
    DailyOverviewTreatmentsStoreModule,
    TemporaryInvoiceStoreModule,
    CutoffStoreModule,
    DailySummaryStoreModule,
    BillsOrdinationStoreModule,
    InvoiceArchivesStoreModule,
    RejectedInvoicesWidgetStoreModule,
    SuspendedInvoicesWidgetStoreModule,
    OccupancyChartWidgetStoreModule,
    RevenueChartWidgetStoreModule,
    AdrRevparChartWidgetStoreModule,
    DailyOccupancyChartWidgetStoreModule,
    ReservationsStatsStoreModule,
    InvoicesStatsStoreModule,
    MovedBillInformationsStoreModule,
    InvoiceNumberStoreModule,
    CustomersStatsWidgetsStoreModule,
    ReservationDetailsPaymentsStoreModule,
    InvoiceChannelsStoreModule,
    InvoicesModuleStoreModule,
    OpenedInvoicesStatsStoreModule,
    DepositsStatsStoreModule,
    CloneableOptionsStoreModule,
    ChannelScoreStoreModule,
    ChannelGuestReviewsStoreModule,
    ScaConfirmationModuleConfigurationStoreModule,
    PropertiesExpireDateStoreModule,
    CommissionsStoreModule,
    ReservationDetailsCancellationPolicyStoreModule,
    DeleteCustomersFromEmailStoreModule,
    ProportionalDayPriceStoreModule,
    PropertiesPaymentsStoreModule,
    PropertiesDocumentsStoreModule,
    ProductionDepartmentsStoreModule,
    ProductionTreatmentsStoreModule,
    ProductionAccommodationsStoreModule,
    ProductionReservationsStoreModule,
    ProductionOccupancyStoreModule,
    AuthStoreModule,
    PasswordResetStoreModule,
    AuthVerificationMethodsStoreModule,
    CityTaxSummaryStatisticsStoreModule,
    StripeCustomerStoreModule,
    PaymentOrdersStoreModule,
    CustomerPaymentsStoreModule,
    DailyClosureWidgetsStoreModule,
    MessageFormatStoreModule,
    CompanyPaymentsStoreModule,
    ChannelsCheckInStoreModule,
    SendinblueStoreModule,
    HelpStoreModule,
    EstimateStoreModule,
    BillsDepartmentsStoreModule,
    PushNotificationsStoreModule,
    BookingPromotionsStoreModule,
    TableauReservationDetailsStoreModule,
    AvailableRoomsStoreModule,
    MetasearchStoreModule,
    PropertyGroupUsersStoreModule,
    FloorsStoreModule,
    ZdlakeStoreModule,
    CheckObStoreModule,
    HousekeeperSettingsStoreModule,
    HousekeeperSortablesStoreModule,
    HousekeeperUsersStoreModule,
    HousekeeperTasksStoreModule,
    HousekeeperRecordTasksStoreModule,
    CleanablesStoreModule,
    ModulesStoreModule,
    HousekeeperDashboardStoreModule,
    HousekeeperStatisticsStoreModule,
    QuestionsDatesOfSearchStoreModule,
    QuestionsDatesOfStayStoreModule,
    TypeOfGuestsStoreModule,
    SourceCountriesStoreModule,
    SourceCountriesChartStoreModule,
    LengthOfStayStoreModule,
    CityDestinationsStoreModule,
    ZipCodeDestinationsStoreModule,
    DateOfCheckinStoreModule,
    DateOfStayStoreModule,
    QuoteRequestWidgetsStoreModule,
    QuoteRequestsStoreModule,
    ChannelOptionsStoreModule,
    IstatGroupsStoreModule,
    QuoteRequestsStatsStoreModule,
    AdminStatsStoreModule,
    UserNotesStoreModule,
    ApiPartnersLookupStoreModule,
    RemoteCheckAccommodationStatusStoreModule,
    MaintenanceReportsStoreModule,
    EventsModalAttacherStoreModule,
    HousekeeperScenariosStoreModule,
    PrivacyPolicyStoreModule,
    InvoiceTypeStoreModule,
    UserSessionsStoreModule,
    WelcomeAutomaticEmailsTemplateStoreModule,
    WelcomeAutomaticEmailsStoreModule,
    ViewSdiFormatStoreModule,
    ReservationDetailsMessagesStoreModule,
    SnippetsStoreModule,
    PreviousReservationsTasksStoreModule,
    PreviousReservationsTaskRecordsStoreModule,
    GuestCardTypesStoreModule,
    GuestCardsStoreModule,
    ContentAPILeadTimeStoreModule,
    ContentAPILeadTimeHoursStoreModule,
    EmailTemplatesSystemStoreModule,
    GdprPrivacyStoreModule,
    RelatedUsersStoreModule,
    CityTaxPresetsStoreModule,
    SystemInformationsStoreModule,

    StoreModule.forRoot({}, {}),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([]),
  ],
})
export class RootStoreModule {}

import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ScaService } from '../../services/sca.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class ScaStoreEffects {
  constructor(
    private dataService: ScaService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  sendByEmailRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.sendByEmailRequest),
      switchMap(({ email, reservation_id, payment_id, lang }) =>
        this.dataService
          .sendEmail({ email, reservation_id, payment_id, lang })
          .pipe(
            map(() => {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant('email_sended'),
                type: 'success',
              });
              return fromActions.sendByEmailSuccess();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.sendByEmailFailure(error));
            }),
          ),
      ),
    ),
  );
}

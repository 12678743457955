import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticatedGuard } from './guards/authenticated.guard';
import { GuestGuard } from './guards/guest.guard';
import { RoleGuard } from './guards/role.guard';

const routes: Routes = [
  {
    path: 'public/newsletter/confirmation',
    loadChildren: () =>
      import(
        './features/public/newsletter-courtesy-pages/newsletter-courtesy-pages.module'
      ).then((m) => m.NewsletterCourtesyPagesModule),
  },
  {
    path: '503',
    loadChildren: () =>
      import(
        './features/commons/service-unavailable/service-unavailable.module'
      ).then((m) => m.ServiceUnavailableModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./shells/admin-shell/admin-shell.module').then(
        (m) => m.AdminShellModule,
      ),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticatedGuard, RoleGuard],
    data: {
      role: 'admin',
    },
  },
  {
    path: 'channel_connect/airbnb/result',
    loadChildren: () =>
      import(
        './channel-connections/airbnb-connection/airbnb-connection.module'
      ).then((m) => m.AirbnbConnectionModule),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticatedGuard, RoleGuard],
    data: {
      role: 'admin',
    },
  },
  {
    path: 'housekeeper',
    loadChildren: () =>
      import('./shells/housekeeper-shell/housekeeper-shell.module').then(
        (m) => m.HousekeeperShellModule,
      ),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticatedGuard, RoleGuard],
    data: {
      role: 'housekeeper',
    },
  },
  {
    path: '',
    loadChildren: () =>
      import('./shells/user-shell/user-shell.module').then(
        (m) => m.UserShellModule,
      ),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticatedGuard, RoleGuard],
    data: {
      role: 'user',
    },
  },
  {
    path: 'booking-engine/quotes',
    loadChildren: () =>
      import(
        './features/bol-reservation-quotes/bol-reservation-quotes.module'
      ).then((m) => m.BolReservationQuotesModule),
  },
  {
    path: 'booking-engine/reservation_delete',
    loadChildren: () =>
      import(
        './features/public/bol-reservation-delete/bol-reservation-delete.module'
      ).then((m) => m.BolReservationDeleteModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./features/commons/login/login.module').then(
        (m) => m.LoginModule,
      ),
    canActivate: [GuestGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'login/password-reset',
    loadChildren: () =>
      import('./features/commons/password-reset/password-reset.module').then(
        (m) => m.PasswordResetModule,
      ),
    canActivate: [GuestGuard],
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { DownloadMappingChannelService } from '../../services/download-mapping-channel.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';
import { effectHooks } from '../../helpers';

@Injectable()
export class DownloadMappingChannelStoreEffects {
  constructor(
    private dataService: DownloadMappingChannelService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  download$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.downloadRequest),
      switchMap(({ propertyId, channelId, hooks }) =>
        this.dataService.downloadRooms(propertyId, channelId).pipe(
          effectHooks(hooks),
          map((response) => {
            return fromActions.downloadSuccess({
              data: response,
              propertyId,
              channelId,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.downloadFailure(error));
          }),
        ),
      ),
    ),
  );
  checkRoomsDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.checkRoomDeletedRequest),
      switchMap(({ propertyId, channelId, hooks }) =>
        this.dataService.checkRoomsDeleted(propertyId, channelId).pipe(
          effectHooks(hooks),
          map((response) => {
            return fromActions.checkRoomDeletedSuccess({
              data: response,
              propertyId,
              channelId,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.checkRoomDeletedFailure(error));
          }),
        ),
      ),
    ),
  );
}

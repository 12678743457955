import { IExtraBedRootStore } from '@app/models';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getExtraBeds = (state: State): any => state.extraBedsProperties;

export const selectExtraBedsState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('extraBeds');

export const selectExtraBedsData: MemoizedSelector<
  object,
  IExtraBedRootStore
> = createSelector(
  selectExtraBedsState,
  getExtraBeds,
);

export const selectExtraBedsError: MemoizedSelector<
  object,
  any
> = createSelector(
  selectExtraBedsState,
  getError,
);

export const selectExtraBedsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  selectExtraBedsState,
  getIsLoading,
);

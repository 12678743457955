import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const selectState = createFeatureSelector<State>('privacyPolicy');

export const selectError = createSelector(
  selectState,
  (state: State) => state.error,
);

export const selectLoading = createSelector(
  selectState,
  (state: State) => state.loading,
);

export const selectPrivacyPolicy = createSelector(
  selectState,
  (state: State) => state.privacyPolicy,
);

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { ChannelAccommodationDownloadSummary } from '../core/models/api/generics/channel-tools-response/channel-tools-response.interface';

@Injectable({
  providedIn: 'root',
})
export class DownloadMappingChannelService {
  constructor(private http: HttpClient) {}

  downloadRooms(propertyId: number, channelId: number) {
    return this.http.get<
      IResponseSuccess<ChannelAccommodationDownloadSummary[]>
    >(
      `channelmanager/accommodations/${channelId}/${propertyId}/new?with_channel_response=1&with_channel_request=1`,
    );
  }

  checkRoomsDeleted(propertyId: number, channelId: number) {
    return this.http.get(
      `channelmanager/accommodations/${channelId}/${propertyId}/delete?with_channel_response=1&with_channel_request=1`,
    );
  }
}

import { Injectable } from '@angular/core';
import { AddonsService } from '@app/services/addons.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as featureActions from './actions';

@Injectable()
export class AddonsInvoiceStoreEffects {
  constructor(private dataService: AddonsService, private actions$: Actions) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) => {
        const { invoiceDestinationId } = action.payload;
        return this.dataService.loadAddonsInvoice(invoiceDestinationId).pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadSuccessAction({
                items: data,
                invoiceDestinationId,
              }),
          ),
          catchError((error) =>
            of(new featureActions.LoadFailureAction({ error })),
          ),
        );
      }),
    ),
  );
}

import { createAction, props } from '@ngrx/store';

export const sendByEmailRequest = createAction(
  '[SCA] Send By Email Request',
  props<{
    email: string;
    reservation_id: number;
    payment_id?: number;
    lang: string;
  }>(),
);

export const sendByEmailSuccess = createAction('[SCA] Send By Email Success');

export const sendByEmailFailure = createAction(
  '[SCA] Send By Email Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[SCA] Reset state');

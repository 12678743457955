import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { BookingEngineService } from './../../services/booking-engine.service';
import * as fromActions from './actions';

@Injectable()
export class BookingEngineStoreEffects {
  constructor(
    private dataService: BookingEngineService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private router: Router,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadBookingEngineRequest),
      switchMap(({ widgetCode }) =>
        this.dataService.load(widgetCode).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadBookingEngineSuccess({
              data: response.data[0],
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadBookingEngineFailure(error));
          }),
        ),
      ),
    ),
  );

  loadFirstStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadBookingEngineFirstStepRequest),
      switchMap(({ objects, lang, widgetCode, propertyId }) =>
        this.dataService
          .loadFirstStep(objects, lang, widgetCode, propertyId)
          .pipe(
            map((response: IResponseSuccess) =>
              fromActions.loadBookingEngineFirstStepSuccess({
                data: response.data[0],
              }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadBookingEngineFirstStepFailure(error));
            }),
          ),
      ),
    ),
  );

  loadSecondStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadBookingEngineSecondStepRequest),
      switchMap(({ dataSecondStep, widget_code }) =>
        this.dataService.loadSecondStep(dataSecondStep).pipe(
          map((response: IResponseSuccess) => {
            this.router.navigate(['booking-engine', widget_code, '2']);
            return fromActions.loadBookingEngineSecondStepSuccess({
              data: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadBookingEngineSecondStepFailure(error));
          }),
        ),
      ),
    ),
  );
}

import { createAction, props } from '@ngrx/store';

export const loadRequest = createAction(
  '[Privacy Policy] Load Request',
  props<{
    reservtionId: number;
    onSuccess?: (privacyPolicy: string) => void;
    onFailure?: () => void;
  }>(),
);

export const loadSuccess = createAction(
  '[Privacy Policy] Load Success',
  props<{ data: string }>(),
);

export const loadFailure = createAction(
  '[Privacy Policy] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Privacy Policy] Reset State');

import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { InvoicesService } from '../../services/invoices.service';

import * as fromActions from './actions';

@Injectable()
export class ViewSdiFormatStoreEffects {
  constructor(
    private dataService: InvoicesService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ id }) =>
        this.dataService.loadDetailShow(id).pipe(
          map((response: string) =>
            fromActions.loadSuccess({
              xml: response,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}

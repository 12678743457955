import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ViewSdiFormatStoreEffects } from './effects';
import { viewSdiFrmatReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('viewSdiFormat', viewSdiFrmatReducer),
    EffectsModule.forFeature([ViewSdiFormatStoreEffects]),
  ],
})
export class ViewSdiFormatStoreModule {}

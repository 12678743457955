import { IBookingEngine, IBookingEngineFirstStep } from '../../models';

import { IBookingEngineSecondStepSelected } from './../../models/objects/booking-engine';

export interface State {
  isLoading?: boolean;
  error?: any;
  data: IBookingEngine;
  firstStep: IBookingEngineFirstStep;
  secondStep: IBookingEngineSecondStepSelected;
}

export const initialState: State = {
  isLoading: false,
  error: null,
  data: null,
  firstStep: null,
  secondStep: null,
};

import { Action } from '@ngrx/store';

import { IAddonRequestRoot, IAddonRoot } from './../../models/objects/addons';

export enum ActionTypes {
  LOAD_REQUEST = '[Addons Invoice] Load Request',
  LOAD_FAILURE = '[Addons Invoice] Load Failure',
  LOAD_SUCCESS = '[Addons Invoice] Load Success',

  RESET_STATE = '[Addon] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { invoiceDestinationId: number }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(
    public payload: { items: IAddonRoot[]; invoiceDestinationId: number },
  ) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | ResetSuccessAction;

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, skipWhile, switchMap, take } from 'rxjs/operators';

import { TokenService } from '../core/services/token.service';
import { RootState } from '../root-store/root-state';
import { UserMeStoreSelectors } from '../root-store/user-me-store';

@Injectable({
  providedIn: 'root',
})
export class GuestGuard  {
  constructor(
    private store: Store<RootState>,
    private router: Router,
    private tokenService: TokenService,
  ) {}

  canActivate() {
    return this.getFromStore().pipe(
      switchMap(() => {
        this.router.navigate(['/']);
        return of(true);
      }),
      catchError(() => {
        this.tokenService.delete();
        return of(true);
      }),
    );
  }

  getFromStore() {
    return this.store.pipe(
      select(UserMeStoreSelectors.selectState),
      skipWhile((userMeState) => userMeState.isLoading === true),
      filter((userMeState) => !!userMeState.data.id),
      take(1),
    );
  }
}

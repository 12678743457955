import { IPagination } from '../../core/models/api/pagination/pagination.model';

export interface State {
  isLoading?: boolean;
  error?: any;
  pagination: IPagination;
}

export const initialState: State = {
  isLoading: false,
  error: null,
  pagination: null,
};
